<template>
  <v-card>
    <v-card-title>
      Manage Yards &nbsp;&nbsp;
      <v-btn color="primary" @click="yardBuilderClicked()">
        Add Yard
      </v-btn>
      <v-spacer> </v-spacer>
      <v-text-field
        v-model="searchTerm"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <div v-if="showYardBuilderComponent"><v-dialog :persistent="true" v-model="setShowYardBuilderComponent" max-width="800"><yard-builder></yard-builder></v-dialog></div>
    <v-data-table
      :headers="headers"
      :items="filteredYards"
      v-bind:disable-pagination="true"
      v-bind:hide-default-footer="true"
      v-bind:fixed-header="true"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      height="700"
    >

      <template v-slot:item.groups="{ item }">
        <td>{{ getGroups(item.groups) }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import yardBuilder from './yardBuilder.vue';

export default {
  components: { yardBuilder },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Groups', value: 'groups', sortable: false },
      { text: 'Yard ID', value: 'yardId' },
      { text: 'Appointment Start Time', value: 'formattedStartTime', sortable: false },
      { text: 'Appointment End Time', value: 'formattedEndTime', sortable: false },
      { text: 'Max Appointment Overlap', value: 'maxAppointmentOverlap' },
    ],
    search: null,
  }),
  computed: {
    searchTerm: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      },
    },
    ...mapGetters('yard', ['yards', 'isLoading', 'filter', 'filteredYards', 'showYardBuilderComponent']),
  },
  methods: {
    ...mapActions('yard', ['getYards', 'setFilter', 'setShowYardBuilderComponent']),
    getGroups(groups) {
      const groupNames = _.map(groups, 'name');
      return groupNames.join(', ');
    },
    yardBuilderClicked() {
      this.setShowYardBuilderComponent(true);
    },
  },
  created() {
    if (this.filteredYards.length === 0) {
      this.$store.dispatch('yard/getYards');
    }
  },
  beforeDestroy() {
    this.setFilter('');
  },
};
</script>
