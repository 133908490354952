<template>
    <v-card>
      <v-card-title> Enter Yard Information Below: </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="yardBuilderForm">
            <v-row class="pb-0">
              <v-text-field
                v-model="yardName"
                label="Yard Name"
                :rules="[rules.required, rules.spaceAtStart]"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="yardId"
                label="Yard ID"
                :rules="[rules.required, rules.counter, rules.spaceAtStart]"
              ></v-text-field>
            </v-row>
            <v-row class="groups">
              <v-row class="form-row py-0 my-0" v-for="(group, index) in yardGroups" :key="index">
                <v-col class="form-group py-0 my-0">
                  <v-text-field
                    v-model="group.name"
                    label="Group Name"
                    :name="`groups[${index}][name]`"
                    class="form-control"
                    :rules="[rules.required, rules.spaceAtStart]"
                  ></v-text-field>
                </v-col>
                <v-col class="form-group py-0 my-0">
                  <v-text-field
                    v-model="group.rangeStart"
                    label="Group Start"
                    :name="`groups[${index}][rangeStart]`"
                    class="form-control"
                    :rules="[rules.required, rules.isNumberOrChar, rules.spaceAtStart]"
                  ></v-text-field>
                </v-col>
                <v-col class="form-group py-0 my-0">
                  <v-text-field
                    v-model="group.rangeEnd"
                    label="Group End"
                    :name="`groups[${index}][rangeEnd]`"
                    class="form-control"
                    :rules="[rules.required, rules.isNumberOrChar, rules.spaceAtStart]"
                  ></v-text-field>
                </v-col>
                <v-col class="form-group py-0 my-0">
                  <v-select
                    v-model="group.type"
                    label="Type"
                    :items="items"
                    :name="`groups[${index}][type]`"
                    class="form-control"
                    :rules="[rules.requiredSelect]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-btn @click="addGroup" type="button" class="btn btn-secondary">Add Group</v-btn>
            </v-row>
            <v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="startMenu"
                    v-model="startTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="yardAppointmentStartTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="yardAppointmentStartTime"
                        label="Appointment Start Time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="startTimeMenu"
                      v-model="yardAppointmentStartTime"
                      landscape
                      no-title
                      full-width
                      :max="yardAppointmentEndTime"
                      @click:minute="$refs.startMenu.save(yardAppointmentStartTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="endMenu"
                    v-model="endTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="yardAppointmentEndTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="yardAppointmentEndTime"
                        label="Appointment End Time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="endTimeMenu"
                      v-model="yardAppointmentEndTime"
                      landscape
                      no-title
                      full-width
                      :min="yardAppointmentStartTime"
                      @click:minute="$refs.endMenu.save(yardAppointmentEndTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
              <v-text-field
                v-model="yardMaxAppointmentOverlap"
                label="Maximum Appointment Overlap"
                :rules="[rules.isNumber]"
              ></v-text-field>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="reset"> Close </v-btn>
        <v-btn id="save" color="primary" text @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['visible'],
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      requiredSelect: (value) => value?.length > 0 || 'At least 1 item is required to be selected.',
      isNumber: (value) => (!Number.isNaN(Number(value)) && Number.isInteger(Number(value)) && value !== ' ') || 'Must be a postive integer',
      isNumberOrChar: (value) => (!Number.isNaN(Number(value)) && Number.isInteger(Number(value))) || (/[a-zA-Z]/.test(value) && value.length === 1) || 'Must be an integer or letter.',
      counter: (value) => value?.length <= 7 || 'Max 7 characters',
      spaceAtStart: (value) => !/^\s+/.test(value) || 'Must not start with spaces.',
    },
    items: ['Spot', 'Door'],
    startTimeMenu: false,
    endTimeMenu: false,
  }),
  computed: {
    yardName: {
      get() {
        return this.yard.name;
      },
      set(value) {
        this.yard.name = value;
      },
    },
    yardId: {
      get() {
        return this.yard.yardId;
      },
      set(value) {
        this.yard.yardId = value;
      },
    },
    yardGroups: {
      get() {
        return this.yard.groups;
      },
      set(value) {
        this.yard.groups = value;
      },
    },
    yardAppointmentStartTime: {
      get() {
        return this.yard.appointmentStartTime;
      },
      set(value) {
        this.yard.appointmentStartTime = value;
      },
    },
    yardAppointmentEndTime: {
      get() {
        return this.yard.appointmentEndTime;
      },
      set(value) {
        this.yard.appointmentEndTime = value;
      },
    },
    yardMaxAppointmentOverlap: {
      get() {
        return this.yard.maxAppointmentOverlap;
      },
      set(value) {
        this.yard.maxAppointmentOverlap = value;
      },
    },
    ...mapGetters('yard', ['yard']),
  },
  watch: {

  },
  methods: {
    ...mapActions({
      createYard: 'yard/createYard',
      resetState: 'yard/resetState',
      setShowComponent: 'yard/setShowYardBuilderComponent',
      addToGroups: 'yard/addToGroups',
      setYardBuilder: 'yard/setYardBuilder',
    }),
    save() {
      if (this.$refs.yardBuilderForm.validate()) {
        this.createYard({
          yard: this.yard,
        }).then(
          this.reset(),
        );
      }
    },
    addGroup() {
      const group = {
        name: '',
        rangeStart: '',
        rangeEnd: '',
        type: '',
      };
      this.addToGroups(group);
    },
    reset() {
      this.setYardBuilder();
      this.setShowComponent(false);
    },
  },
};
</script>
