<template>
<div>
<manage-yards></manage-yards>
</div>
</template>

<script>
import ManageYards from '@/components/manageYards.vue';

export default {
  components: { ManageYards },
};
</script>
